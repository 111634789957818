import config from "@config"
import { usePaymentChannel } from "@hooks/usePaymentChannel"
import { useQuery } from "@hooks/useQuery"
import { useStripe } from "@libs/stripe-lib"
import { useEffect } from "react"
import { toast } from "sonner"

const StripeCheckout = () => {
    const query = useQuery()
    const sessionId = query.get("sessionId")
    const stripeUtils = useStripe(config.VITE_STRIPE_PUBLIC_KEY)
    const { set } = usePaymentChannel()

    useEffect(() => {
        if (sessionId) {
            set({ id: sessionId, status: "processing" })
            void stripeUtils.createCheckoutSession(sessionId).then((response) => {
                if (response.error) {
                    toast.error("Error processing payment")
                    console.error(response.error)
                }
            })
        } else {
            toast.error("No stripe session id provided")
        }
    }, [sessionId, set, stripeUtils])

    return (
        <div>
            <h1>Processing Payment...</h1>
        </div>
    )
}

export { StripeCheckout }
