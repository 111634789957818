import { useUsageLimits } from "@api/usage-tracker/useUsageLimits"
import { useUsageTracker } from "@api/usage-tracker/useUsageTracker"
import { useUserSubscription } from "@api/user-subscriptions/useUserSubscription"
import { useMutateUser } from "@api/user/useMutateUser.ts"
import { useMutateUserAvatar } from "@api/user/useMutateUserAvatar.ts"
import { useMutateUserEmail } from "@api/user/useMutateUserEmail.ts"
import { useMutateUserPassword } from "@api/user/useMutateUserPassword.ts"
import { useRemoveUserAvatar } from "@api/user/useRemoveUserAvatar.ts"
import { useUser } from "@api/user/useUser.ts"
import { Button } from "@components/atoms/buttons"
import { LoadingDots } from "@components/atoms/buttons/loading-dots.tsx"
import { Heading, Text } from "@components/atoms/typography.tsx"
import { ContentOffset } from "@components/molecules/content-offset.tsx"
import { Logout } from "@components/molecules/logout.tsx"
import { ChangePassword, ChangeableImage, ChangeableText } from "@components/organisms/changeable-field.tsx"
import { DeleteAccount } from "@components/organisms/delete-account.tsx"
import { isFreeSubscription, SubscriptionSchema } from "@energuide/shared"
import { useMobile } from "@hooks/useMobile"
import { useAppState } from "@hooks/useState.ts"
import { useTitlebar } from "@hooks/useTitlebar.ts"
import { capitalize } from "@utils"
import { DateTime } from "luxon"
import { useMemo } from "react"
import { useNavigate } from "react-router"
import { toast } from "sonner"

function EntryLine({ label, value }: { label: string; value: string | number }) {
    return (
        <div className="flex justify-between">
            <Text variant="body2" className="text-text">
                {label}
            </Text>
            <Text variant="body2" className="text-textStrong" style={{ fontWeight: "bold" }}>
                {value}
            </Text>
        </div>
    )
}

export function Account() {
    const activeProjectId = useAppState((state) => state.activeProjectId)
    const isMobile = useMobile()

    useTitlebar({
        title: "",
        mode: "back",
        backTo: `/authenticated/chat/${activeProjectId}`,
        showContextMenu: false,
    })

    const { isPending, data: account } = useUser()
    const { data: userSubscription } = useUserSubscription()

    const userSubscriptionType = useMemo(() => {
        if (!userSubscription) {
            return null
        }
        const subscription = SubscriptionSchema.parse(userSubscription.subscriptionRef)
        return subscription.name
    }, [userSubscription])

    const formattedSubscriptionStatus = useMemo(() => {
        switch (userSubscription?.status) {
            case "active":
                return "Aktiv"
            case "active_canceled":
                return `Aktiv (bis ${DateTime.fromISO(userSubscription.currentPeriodEnd!).setLocale("de-DE").toLocaleString()})`
            case "canceled":
                return "Gekündigt"
            default:
                return ""
        }
    }, [userSubscription])

    const { data: usageTracker } = useUsageTracker()
    const { data: usageLimits } = useUsageLimits()

    const userId = account?.id ?? null
    const mutation = useMutateUser(userId)
    const avatarMutation = useMutateUserAvatar(userId)
    const removeAvatarMutation = useRemoveUserAvatar(userId)
    const emailMutation = useMutateUserEmail()
    const passwordMutation = useMutateUserPassword()
    const navigate = useNavigate()

    const { displayName, email, avatar, provider } = account ?? {}
    const isEmail = provider === "local"

    if (isPending) {
        return (
            <div className="grid h-full place-content-center">
                <LoadingDots />
            </div>
        )
    }

    return (
        <ContentOffset safeAreas={false} offsetAppbar={true}>
            <section className="mx-auto grid max-w-screen-md content-start gap-12 p-8">
                <section className="grid gap-4">
                    <Heading level="h2">Konto</Heading>
                    <ChangeableImage
                        value={avatar?.url ?? ""}
                        changeableFieldProps={{
                            mode: "edit",
                            label: "Avatar",
                        }}
                        onMutate={async (data) => avatarMutation.mutateAsync({ data })}
                        onDelete={async () => removeAvatarMutation.mutateAsync()}
                        data-testid="avatar-container"
                    />
                    <ChangeableText
                        changeableFieldProps={{
                            mode: "edit",
                            label: "Name",
                        }}
                        inputProps={{
                            name: "displayName",
                            value: displayName ?? "---",
                            required: true,
                            messages: [
                                {
                                    content: "Name benötigt",
                                    match: "valueMissing",
                                },
                            ],
                        }}
                        onMutate={async (data) => {
                            await mutation.mutateAsync({ data })
                        }}
                        data-testid="name-container"
                    />
                    <EntryLine label="Kunden-ID" value={userId ?? "-"} />
                    <ChangeableText
                        changeableFieldProps={{
                            mode: isEmail ? "edit" : "none",
                            label: isEmail ? "E-Mail" : `Eingeloggt mit ${capitalize(provider ?? "provider")}`,
                        }}
                        inputProps={{
                            name: "newEmail",
                            value: email,
                            disabled: !isEmail,
                            required: true,
                            messages: [
                                {
                                    content: "E-Mail benötigt",
                                    match: "valueMissing",
                                },
                            ],
                        }}
                        onMutate={async (data) => emailMutation.mutateAsync({ data })}
                        data-testid="email-container"
                    />
                    {isEmail && (
                        <ChangePassword
                            onSubmit={async (data) => passwordMutation.mutateAsync({ data })}
                            data-testid="password-container"
                        />
                    )}
                </section>
                <section className="grid gap-4">
                    <Heading level="h2">Abonnement</Heading>
                    <Text variant="body2" className="text-textVeryLight">
                        Bearbeite dein Abonnement
                    </Text>
                    <EntryLine label="Aktueller Plan" value={userSubscriptionType?.toString() ?? ""} />
                    {!isFreeSubscription(userSubscription?.subscriptionRef.id) && (
                        <>
                            <EntryLine label="Abonnement Status" value={formattedSubscriptionStatus} />
                            <EntryLine label="In Probe" value={userSubscription?.inTrial ? "Ja" : "Nein"} />
                        </>
                    )}
                    {userSubscription && !isFreeSubscription(userSubscription.subscriptionRef.id) ? (
                        userSubscription.endDate ? (
                            <EntryLine
                                label="Abo Läuft Bis"
                                value={DateTime.fromISO(userSubscription.endDate).setLocale("de-DE").toLocaleString()}
                            />
                        ) : (
                            <EntryLine
                                label="Nächste Abrechnung"
                                value={
                                    userSubscription.currentPeriodEnd
                                        ? DateTime.fromISO(userSubscription.currentPeriodEnd)
                                              .setLocale("de-DE")
                                              .toLocaleString()
                                        : ""
                                }
                            />
                        )
                    ) : null}

                    <EntryLine label="Abo-ID" value={userSubscription?.id ?? "-"} />

                    <Button
                        variant="primary"
                        onClick={() =>
                            isMobile
                                ? toast.error("Abonnements können nur in der Desktop version geändert werden")
                                : navigate("/auth/subscription")
                        }
                        data-testid="subscriptions-button"
                    >
                        Ändern
                    </Button>
                </section>

                <section className="grid gap-4">
                    <Heading level="h2">Metrics</Heading>
                    <Text variant="body2" className="text-textVeryLight">
                        Metrics
                    </Text>
                    <div className="flex justify-between">
                        <Text variant="body2" className="text-text">
                            Verfügbare Token:
                        </Text>
                        <Text variant="body2" className="text-textStrong" style={{ fontWeight: "bold" }}>
                            {new Intl.NumberFormat("de-DE").format(usageLimits?.limits.token ?? 0)}
                        </Text>
                    </div>
                    <div className="flex justify-between">
                        <Text variant="body2" className="text-text">
                            Verbrauchte Token:
                        </Text>
                        <Text variant="body2" className="text-textStrong" style={{ fontWeight: "bold" }}>
                            {new Intl.NumberFormat("de-DE").format(usageTracker?.tokensUsed ?? 0)}
                        </Text>
                    </div>
                    <span className="h-1 w-full bg-primaryAccent1/60"></span>
                    <div className="flex justify-between">
                        <Text variant="body2" className="text-text">
                            Verfügbare Projekte:
                        </Text>
                        <Text variant="body2" className="text-textStrong" style={{ fontWeight: "bold" }}>
                            {new Intl.NumberFormat("de-DE").format(usageLimits?.limits.projects ?? 0)}
                        </Text>
                    </div>
                    <div className="flex justify-between">
                        <Text variant="body2" className="text-text">
                            Verbrauchte Projekte:
                        </Text>
                        <Text variant="body2" className="text-textStrong" style={{ fontWeight: "bold" }}>
                            {new Intl.NumberFormat("de-DE").format(usageTracker?.projectsUsed ?? 0)}
                        </Text>
                    </div>
                </section>

                <section className="grid gap-6">
                    <Heading level="h2">Logout</Heading>
                    <Logout />
                </section>

                <section className="grid gap-6">
                    <div className="grid gap-2">
                        <Heading level="h2">Konto löschen</Heading>
                        <Text variant="body2" className="text-textVeryLight">
                            Wenn du deine Konto löschst, werden alle mit dir verbundenen Daten von unseren Servern
                            entfernt.
                        </Text>
                    </div>
                    <DeleteAccount />
                </section>
            </section>
        </ContentOffset>
    )
}
