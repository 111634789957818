import { IEtDatens } from "@api/et-daten/schema"
import { InfoCard } from "@components/molecules/info-card"
import { DateTime } from "luxon"

type EnergyBillCardProps = {
    etDaten: IEtDatens
}

export function EnergyBillCard(props: EnergyBillCardProps) {
    const { etDaten } = props

    return (
        <div>
            {etDaten.map((et) => (
                <div className="grid gap-2" key={et.id}>
                    <span className="font-heading text-body2 text-text">
                        Energieträger: {et.Energietraeger_Verbrauch}
                    </span>
                    {et.ZRDaten?.map((zr) => (
                        <InfoCard key={zr.id}>
                            <InfoCard.InfoAttribute>Abrechnungsdokument</InfoCard.InfoAttribute>
                            <InfoCard.InfoContent>
                                {zr.Abrechnungsdokument ? (
                                    <a
                                        href={zr.Abrechnungsdokument ?? ""}
                                        target="_blank"
                                        className="underline underline-offset-1 hover:no-underline"
                                    >
                                        <i className="ri-link"></i> Ansehen
                                    </a>
                                ) : (
                                    <span className="text-textLight">
                                        <i className="ri-information-line"></i> Kein Abrechnungsdokument gefunden
                                    </span>
                                )}
                            </InfoCard.InfoContent>

                            <InfoCard.InfoAttribute>Start Datum</InfoCard.InfoAttribute>
                            <InfoCard.InfoContent>
                                {zr.Startdatum
                                    ? DateTime.fromISO(zr.Startdatum).setLocale("de-DE").toLocaleString()
                                    : ""}
                            </InfoCard.InfoContent>

                            <InfoCard.InfoAttribute>End Datum</InfoCard.InfoAttribute>
                            <InfoCard.InfoContent>
                                {zr.Enddatum ? DateTime.fromISO(zr.Enddatum).setLocale("de-DE").toLocaleString() : ""}
                            </InfoCard.InfoContent>

                            <InfoCard.InfoAttribute>Verbrauchte Menge</InfoCard.InfoAttribute>
                            <InfoCard.InfoContent>{zr.Verbrauchte_Menge}</InfoCard.InfoContent>
                        </InfoCard>
                    ))}
                </div>
            ))}
        </div>
    )
}
