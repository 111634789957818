import { IEnergyCertificate } from "@api/certificate/schema"
import { useGDaten } from "@api/g-daten/useGDaten"
import { GradientBackground } from "@components/atoms/gradient"
import { InfoCard } from "@components/molecules/info-card"
import { EnergyCertificatTypeMap } from "@energuide/shared"
import { DateTime } from "luxon"
import { Link } from "react-router-dom"

type ReviewCardProps = {
    certificate?: IEnergyCertificate | null
}

export function ReviewCard(props: ReviewCardProps) {
    const { certificate } = props
    const { id, type, taskId, createdAt, project } = certificate ?? {}
    const { data: gDaten } = useGDaten(project?.id ?? 0, { enabled: !!project })

    return (
        <GradientBackground
            variant="light"
            radius="small"
            onClick={() => {}}
            className="bg-primary/15 hover:bg-primary/25"
        >
            <Link to={`/authenticated/review/${id}`}>
                {gDaten?.Gebaeude_Foto && <img src={gDaten.Gebaeude_Foto} alt="house image" className="w-full" />}
                <div className="grid grid-cols-[auto,minmax(0,1fr)] gap-x-4 gap-y-1 p-4">
                    <InfoCard.InfoAttribute>Typ</InfoCard.InfoAttribute>
                    <InfoCard.InfoContent>{type ? EnergyCertificatTypeMap[type] : ""}</InfoCard.InfoContent>

                    <InfoCard.InfoAttribute>Projekt</InfoCard.InfoAttribute>
                    <InfoCard.InfoContent>{project?.name ?? ""}</InfoCard.InfoContent>

                    <InfoCard.InfoAttribute>Projekt ID</InfoCard.InfoAttribute>
                    <InfoCard.InfoContent>{project?.id ?? ""}</InfoCard.InfoContent>

                    <InfoCard.InfoAttribute>Task ID</InfoCard.InfoAttribute>
                    <InfoCard.InfoContent>{taskId ?? ""}</InfoCard.InfoContent>

                    <InfoCard.InfoAttribute>Erstellt</InfoCard.InfoAttribute>
                    <InfoCard.InfoContent>
                        {createdAt ? DateTime.fromISO(createdAt).toFormat("dd.MM.yyyy - kk:mm") : ""}
                    </InfoCard.InfoContent>
                </div>
            </Link>
        </GradientBackground>
    )
}
