import { IUser } from "."
import { ACCOUNT_TYPES } from "../account-type"
import { EnterpriseSubscriptionId, PrivateSubscriptionId } from "../subscription"

export interface ITestUser extends IUser {
    password: string
    subscriptionId: PrivateSubscriptionId | EnterpriseSubscriptionId | null
}

export const TEST_USER: Omit<ITestUser, "id">[] = [
    {
        username: "eg-Alberto861@team296611.testinator.com",
        email: "eg-Alberto861@team296611.testinator.com",
        password: "password",
        provider: "local",
        confirmed: true,
        blocked: false,
        dibtUsername: null,
        dibtPassword: null,
        displayName: "Alberto",
        pendingEmail: null,
        firstname: null,
        lastname: null,
        projectLimit: 1,
        tokenLimit: 30_000,
        avatar: null,
        stripeCustomerId: null,
        role: 1,
        accountTypeRef: {
            id: ACCOUNT_TYPES.Private.id,
            type: ACCOUNT_TYPES.Private.type,
            pricesIncludeVAT: true,
        },
        subscriptionId: PrivateSubscriptionId.Free,
    },
    {
        username: "eg-Elon777@team296611.testinator.com",
        email: "eg-Elon777@team296611.testinator.com",
        password: "password",
        provider: "local",
        confirmed: true,
        blocked: false,
        dibtUsername: null,
        dibtPassword: null,
        displayName: "Elon Musk",
        pendingEmail: null,
        firstname: null,
        lastname: null,
        projectLimit: 1,
        tokenLimit: 30_000,
        avatar: null,
        stripeCustomerId: null,
        role: 1,
        accountTypeRef: {
            id: ACCOUNT_TYPES.Enterprise.id,
            type: ACCOUNT_TYPES.Enterprise.type,
            pricesIncludeVAT: false,
        },
        subscriptionId: EnterpriseSubscriptionId.L,
    },
    {
        username: "eg-Bilbo@team296611.testinator.com",
        email: "eg-Bilbo@team296611.testinator.com",
        password: "password",
        provider: "local",
        confirmed: true,
        blocked: false,
        dibtUsername: null,
        dibtPassword: null,
        displayName: "Bilbo Beutlin",
        pendingEmail: null,
        firstname: null,
        lastname: null,
        projectLimit: 1,
        tokenLimit: 30_000,
        avatar: null,
        stripeCustomerId: null,
        role: 1,
        accountTypeRef: {
            id: ACCOUNT_TYPES.Private.id,
            type: ACCOUNT_TYPES.Private.type,
            pricesIncludeVAT: true,
        },
        subscriptionId: PrivateSubscriptionId.Free,
    },
    {
        username: "eg-Hanna330@team296611.testinator.com",
        email: "eg-Hanna330@team296611.testinator.com",
        password: "password",
        provider: "local",
        confirmed: true,
        blocked: false,
        dibtUsername: null,
        dibtPassword: null,
        displayName: "Hanna Schmidt",
        pendingEmail: null,
        firstname: null,
        lastname: null,
        projectLimit: 1,
        tokenLimit: 30_000,
        avatar: null,
        stripeCustomerId: null,
        role: 1,
        accountTypeRef: {
            id: ACCOUNT_TYPES.Private.id,
            type: ACCOUNT_TYPES.Private.type,
            pricesIncludeVAT: true,
        },
        subscriptionId: PrivateSubscriptionId.Free,
    },
    {
        username: "eg-Hafti420@team296611.testinator.com",
        email: "eg-Hafti420@team296611.testinator.com",
        password: "password",
        provider: "local",
        confirmed: true,
        blocked: false,
        dibtUsername: null,
        dibtPassword: null,
        displayName: "Aykut Anhan",
        pendingEmail: null,
        firstname: null,
        lastname: null,
        projectLimit: 1,
        tokenLimit: 30_000,
        avatar: null,
        stripeCustomerId: null,
        role: 1,
        accountTypeRef: {
            id: ACCOUNT_TYPES.Private.id,
            type: ACCOUNT_TYPES.Private.type,
            pricesIncludeVAT: false,
        },
        subscriptionId: PrivateSubscriptionId.Pro,
    },
    {
        username: "eg-Abibas@team296611.testinator.com",
        email: "eg-Abibas@team296611.testinator.com",
        password: "password",
        provider: "local",
        confirmed: true,
        blocked: false,
        dibtUsername: null,
        dibtPassword: null,
        displayName: "Abibas AG",
        pendingEmail: null,
        firstname: null,
        lastname: null,
        projectLimit: 1,
        tokenLimit: 30_000,
        avatar: null,
        stripeCustomerId: null,
        role: 1,
        accountTypeRef: {
            id: ACCOUNT_TYPES.Enterprise.id,
            type: ACCOUNT_TYPES.Enterprise.type,
            pricesIncludeVAT: false,
        },
        subscriptionId: EnterpriseSubscriptionId.M,
    },
    {
        username: "eg-startup@team296611.testinator.com",
        email: "eg-startup@team296611.testinator.com",
        password: "password",
        provider: "local",
        confirmed: true,
        blocked: false,
        dibtUsername: null,
        dibtPassword: null,
        displayName: "Startup GmbH",
        pendingEmail: null,
        firstname: null,
        lastname: null,
        projectLimit: 1,
        tokenLimit: 30_000,
        avatar: null,
        stripeCustomerId: null,
        role: 1,
        accountTypeRef: {
            id: ACCOUNT_TYPES.Enterprise.id,
            type: ACCOUNT_TYPES.Enterprise.type,
            pricesIncludeVAT: false,
        },
        subscriptionId: EnterpriseSubscriptionId.Free,
    },
    {
        username: "eg-Blocked-User@team296611.testinator.com",
        email: "eg-Blocked-User@team296611.testinator.com",
        password: "password",
        provider: "local",
        confirmed: true,
        blocked: true,
        dibtUsername: null,
        dibtPassword: null,
        displayName: "Mr. Illegal",
        pendingEmail: null,
        firstname: null,
        lastname: null,
        projectLimit: 1,
        tokenLimit: 30_000,
        avatar: null,
        stripeCustomerId: null,
        role: 1,
        accountTypeRef: {
            id: ACCOUNT_TYPES.Private.id,
            type: ACCOUNT_TYPES.Private.type,
            pricesIncludeVAT: true,
        },
        subscriptionId: PrivateSubscriptionId.Free,
    },
]
