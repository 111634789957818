import { z } from "zod"

const Config = z.object({
    VITE_APP_ENV: z.enum(["local", "production", "staging"]),
    VITE_SENTRY_DSN: z.string(),
    VITE_STRIPE_PUBLIC_KEY: z.string(),
    VITE_BACKEND_URL: z.string(),
    VITE_FRONTEND_URL: z.string(),
})

const config = Config.parse(import.meta.env)
export default config
