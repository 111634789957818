import { Browser } from "@capacitor/browser"
import { cls } from "@utils"
import { PropsWithChildren } from "react"
import { Link } from "react-router-dom"
import { Variant, Radius, variants } from "."

type ILinkButton = PropsWithChildren<{
    href: string
    variant: Variant
    className?: string
    isExternal?: boolean
    onClick?: () => void
    radius?: keyof typeof Radius
    download?: boolean
    inApp?: boolean
    dataTestId?: string
}>

export function LinkButton(props: ILinkButton) {
    const { inApp, children, variant, className, href, isExternal, onClick, radius, download } = props

    const onOpen: React.MouseEventHandler<HTMLAnchorElement> = async (event) => {
        event.preventDefault()
        const href = event.currentTarget.href
        await Browser.open({ url: href, presentationStyle: "popover" })
    }

    if (isExternal) {
        return (
            <a
                className={cls(variants[variant], radius ? Radius[radius] : "rounded-lg", className)}
                href={href}
                onClick={inApp ? onOpen : onClick}
                download={download}
                target="_blank"
                data-testid={props.dataTestId}
            >
                {children}
            </a>
        )
    }

    return (
        <Link
            className={cls(variants[variant], radius ? Radius[radius] : "rounded-lg", className)}
            to={href}
            onClick={onClick}
            data-testid={props.dataTestId}
        >
            {children}
        </Link>
    )
}
