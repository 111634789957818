import { useEnterpriseSubscriptions } from "@api/subscriptions/useEnterpriseSubscriptions"
import { useUser } from "@api/user/useUser"
import { Button } from "@components/atoms/buttons"
import { Text } from "@components/atoms/typography"
import { PricingCard } from "@components/molecules/pricing-card"
import {
    Currency,
    EnterpriseSubscriptionId,
    getSubscriptionInterval,
    isFreeSubscription,
    ISubscription,
    IUserSubscription,
    PrivateSubscriptionId,
    SubscriptionInterval,
} from "@energuide/shared"
import { useTitlebar } from "@hooks/useTitlebar"
import { useMemo, useState } from "react"
import { ToggleSwitch } from "../parts/toggle-switch"

interface Props {
    onSubmit: (subscriptionId: PrivateSubscriptionId | EnterpriseSubscriptionId, interval: SubscriptionInterval) => void
    onUnsubscribe: () => void
    userSubscription: IUserSubscription | null
}

function EnterpriseSubscription(props: Props) {
    const { onSubmit, onUnsubscribe, userSubscription } = props

    useTitlebar({
        title: "Abonnement",
        mode: "back",
        backTo: "/authenticated/account",
        showContextMenu: false,
    })

    const { data: subscriptions } = useEnterpriseSubscriptions()
    const { data: user } = useUser()
    const [paymentInterval, setPaymentInterval] = useState<SubscriptionInterval>(SubscriptionInterval.Yearly)

    const getSubscriptions = useMemo(() => {
        if (userSubscription) {
            const interval = getSubscriptionInterval(userSubscription)
            const modifiedSubscriptions = subscriptions?.map((subscription) => {
                if (
                    subscription.id === userSubscription.subscriptionRef?.id &&
                    (interval === paymentInterval || isFreeSubscription(userSubscription.subscriptionRef.id))
                ) {
                    subscription.disabled = "Aktueller Plan"
                } else {
                    delete subscription.disabled
                }
                return subscription
            })
            return modifiedSubscriptions
        }
        return subscriptions?.map((sub) => ({ ...sub, disabled: undefined }))
    }, [subscriptions, userSubscription, paymentInterval])

    const getPrice = (
        subscription: ISubscription,
        interval: SubscriptionInterval
    ): { price: Currency; discountedPrice: Currency | undefined } => {
        if (interval === SubscriptionInterval.Monthly) {
            return {
                price: new Currency("EUR", "Euro", "€", subscription.monthlyPrice * 100),
                discountedPrice: undefined,
            }
        } else {
            return {
                price: new Currency("EUR", "Euro", "€", subscription.monthlyPrice * 100),
                discountedPrice: new Currency("EUR", "Euro", "€", (subscription.annualPrice * 100) / 12),
            }
        }
    }

    if (!user || user.accountTypeRef === null) {
        return null
    }

    return (
        <div className="flex h-full w-full flex-col bg-gradient-to-tr from-backgroundStrong to-primaryAccent1 pb-10">
            <h1 className="mt-8 text-center font-heading text-heading1 md:mt-8">Enterprise Subscriptions</h1>
            <div className="flex max-w-[100svw] flex-grow flex-col flex-wrap items-center justify-center overflow-hidden">
                <div className="mb-10 mt-10 xl:mb-20 xl:mt-0">
                    <ToggleSwitch
                        onToggle={(i) =>
                            i === "Jährlich"
                                ? setPaymentInterval(SubscriptionInterval.Yearly)
                                : setPaymentInterval(SubscriptionInterval.Monthly)
                        }
                    />
                </div>
                <div data-cards className="mx-auto flex max-w-full flex-col gap-12 overflow-auto px-8 lg:flex-row">
                    {getSubscriptions?.map((subscription, key) => {
                        const price = getPrice(subscription, paymentInterval)

                        return (
                            <div
                                className="aspect-[7/10] h-[500px]"
                                key={key}
                                data-testid={`enterprise-${subscription.name}`}
                            >
                                <PricingCard
                                    inclVAT={user.accountTypeRef!.pricesIncludeVAT}
                                    subscriptionId={subscription.id}
                                    type={subscription.name}
                                    disabled={subscription.disabled}
                                    price={price.price}
                                    discountPrice={
                                        price.discountedPrice && price.discountedPrice.value < price.price.value
                                            ? price.discountedPrice
                                            : undefined
                                    }
                                    description={subscription.description}
                                    attributes={subscription.attributes}
                                    onSubmit={(subId) => onSubmit(subId, paymentInterval)}
                                />
                            </div>
                        )
                    })}
                </div>
                {userSubscription &&
                    userSubscription.status === "active" &&
                    !isFreeSubscription(userSubscription.subscriptionRef.id) && (
                        <div className="mt-10">
                            <Button
                                variant="tertiary"
                                onClick={onUnsubscribe}
                                data-testid="enterprise-cancel-subscription-button"
                            >
                                Kündige hier dein Abonnement
                            </Button>
                        </div>
                    )}
                {userSubscription && userSubscription.status === "active_canceled" && (
                    <div className="mt-10">
                        <Text variant="tiny">
                            {userSubscription.endDate
                                ? `Abonnement gekündigt und läuft bis zum ${new Date(userSubscription.endDate).toLocaleDateString()}`
                                : "Kündigung zum Ende des Abrechnungszeitraums"}
                        </Text>
                    </div>
                )}
            </div>
        </div>
    )
}
export { EnterpriseSubscription }
