import { IProject } from "@api/projects/schema.ts"
import { useWillLoseTrial } from "@api/user-subscriptions/useWillLoseTrial"
import { Button } from "@components/atoms/buttons"
import { Input } from "@components/atoms/input.tsx"
import { Heading, Text } from "@components/atoms/typography.tsx"
import { DataModal } from "@components/organisms/data-modal.tsx"
import * as Form from "@radix-ui/react-form"
import { cls } from "@utils"
import React from "react"
import { Link } from "react-router-dom"
import { toast } from "sonner"

type IProjectList = {
    projects: IProject[]
    selectedId: number
    onAdd: (data: FormData) => Promise<void>
}

export function ProjectList(props: IProjectList) {
    const { projects, selectedId, onAdd } = props
    const [open, setOpen] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)
    const { data: willLoseTrial } = useWillLoseTrial({ resource: "project" })

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()

        try {
            setLoading(true)
            await onAdd(new FormData(event.currentTarget))
            setOpen(false)
        } catch (error: any) {
            console.error(error)
            if (error.message) {
                toast.error(error?.message)
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <nav className="grid h-full grid-rows-[auto_minmax(0,1fr)] gap-4 overflow-hidden px-6 py-8">
            <Heading level="h3">Projekte</Heading>
            <div
                className="grid grid-cols-[minmax(0,1fr)] content-start overflow-auto break-words"
                data-testid="project-list"
            >
                {projects.map((project) => (
                    <Link
                        to={`/authenticated/chat/${project.id}`}
                        className={cls("flex items-center gap-4 rounded-md px-4 py-2 text-text", {
                            "bg-primaryAccent2/30": project.id === selectedId,
                        })}
                        key={project.id}
                    >
                        <i className={cls("ri-home-fill text-[1.5rem] leading-none")}></i>
                        <Text variant="strong2" className="text-text">
                            {project.name}
                        </Text>
                    </Link>
                )) ?? (
                    <Text variant="strong2" className="text-textVeryLight">
                        Kein Projekte
                    </Text>
                )}

                <DataModal
                    iconClass="ri-add-line"
                    title="Projekt erstellen"
                    description="Wie soll das Projekt heißen?"
                    open={open}
                    onOpenChange={setOpen}
                    trigger={
                        <Button
                            variant="freeform"
                            className="flex items-center gap-4 px-4 py-2"
                            data-testid="project-add-button"
                        >
                            <i
                                className={
                                    "ri-add-line grid place-items-center text-[1.5rem] leading-none text-textVeryLight"
                                }
                            ></i>
                            <Text variant="strong2" className=" text-textVeryLight">
                                Hinzufügen
                            </Text>
                        </Button>
                    }
                >
                    <Form.Root className="grid gap-6" onSubmit={onSubmit}>
                        {willLoseTrial?.status === "will-loose" && (
                            <Text variant="body1" className="text-warning">
                                Durch das erstellen des Projektes verliest du dein Recht auf eine Erstattung
                            </Text>
                        )}
                        <Input
                            name="name"
                            type="text"
                            label="Name"
                            required
                            messages={[
                                {
                                    content: "Projektname benötigt",
                                    match: "valueMissing",
                                },
                            ]}
                        />
                        <Button variant="primary" loading={loading} data-testid="project-create-button">
                            Erstellen
                        </Button>
                    </Form.Root>
                </DataModal>
            </div>
        </nav>
    )
}
