import { apiUrl, handleFetch, handleParse, queryClient } from "@api/apiConfig"
import { OrderPost, OrderSchema, ProductId, SubscriptionPost } from "@energuide/shared"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe, Stripe } from "@stripe/stripe-js"
import React from "react"

const stripeInstances: Record<string, Stripe | null> = {}

// Stripe initialization hook (common for both products and subscriptions)
export const useStripe = (pk: string) => {
    const stripePromise: Promise<Stripe | null> = loadStripe(pk)

    const initializeStripe = async () => {
        if (!stripeInstances[pk]) {
            stripeInstances[pk] = await loadStripe(pk)
        }
        return stripeInstances[pk]
    }

    const authHeader = useAuthHeader()

    // Common method for creating a checkout session for Stripe
    const createCheckoutSession = async (sessionId: string) => {
        const stripe = await initializeStripe()
        if (!stripe) {
            throw new Error("Stripe failed to initialize.")
        }

        return stripe.redirectToCheckout({
            sessionId,
        })
    }

    // Product-related logic
    const useProductOrder = () => {
        // Place a product order
        const orderProduct = async (params: OrderPost) => {
            return await handleFetch(() =>
                fetch(`${apiUrl}/order`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        ...authHeader,
                    },
                    body: JSON.stringify(params),
                })
            )
        }

        const getCertificateOrder = async (params: { projectId: number; productId: ProductId }) => {
            const { projectId, productId } = params
            const response = await handleFetch(() =>
                fetch(`${apiUrl}/order?projectId=${projectId}&productId=${productId}`, {
                    method: "GET",
                    headers: {
                        ...authHeader,
                    },
                })
            )
            if (!response) {
                return null
            }

            return handleParse(() => OrderSchema.parse(response))
        }

        // Refresh order status for a product
        const refreshProductOrderStatus = async (orderId: number | string) => {
            const response = await handleFetch(() =>
                fetch(`${apiUrl}/order/refresh?id=${orderId}`, {
                    method: "POST",
                    headers: {
                        ...authHeader,
                    },
                })
            )
            if (!response) {
                return null
            }
            return handleParse(() => OrderSchema.parse(response))
        }

        return {
            orderProduct,
            refreshProductOrderStatus,
            getCertificateOrder,
        }
    }

    const useSubscriptionOrder = () => {
        // Place a subscription order
        const orderSubscription = async (params: SubscriptionPost) => {
            return await handleFetch(() =>
                fetch(`${apiUrl}/user-subscription/subscribe`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        ...authHeader,
                    },
                    body: JSON.stringify(params),
                })
            )
        }
        const cancelSubscription = async () => {
            const response = await handleFetch(() =>
                fetch(`${apiUrl}/user-subscription/cancel`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        ...authHeader,
                    },
                })
            )

            if (!response) {
                return null
            }
            await queryClient.invalidateQueries({ queryKey: ["user-subscription", authHeader] })
            return response
        }

        return {
            orderSubscription,
            cancelSubscription,
        }
    }

    // Stripe Elements component (common for both subscriptions and product orders)
    const StripeElements = (props: React.PropsWithChildren<{ options?: any }>) => {
        const { children, options } = props

        if (!options) {
            return <div>Loading...</div>
        }

        return (
            <Elements stripe={stripePromise} options={options}>
                {children}
            </Elements>
        )
    }

    return {
        createCheckoutSession,
        StripeElements,
        useProductOrder,
        useSubscriptionOrder,
    }
}
