import { Button, LinkButton } from "@components/atoms/buttons"
import { Currency, EnterpriseSubscriptionId, PrivateSubscriptionId } from "@energuide/shared"
import { useMemo, useRef } from "react"
import { PricingCardAttribute, PricingCardAttributeProps } from "./parts/attribute"
import { PricingCardPricing } from "./parts/pricing"
import { SeparationLine } from "./parts/separation-line"

interface Props {
    subscriptionId: PrivateSubscriptionId | EnterpriseSubscriptionId
    type: string
    price: Currency
    discountPrice?: Currency
    description: string
    attributes: PricingCardAttributeProps[]
    disabled?: string
    inclVAT: boolean
}

function PricingCard(props: Props & { onSubmit: (id: PrivateSubscriptionId | EnterpriseSubscriptionId) => void }) {
    const { type, price, discountPrice, description, attributes, disabled, onSubmit, subscriptionId, inclVAT } = props
    const isCheckboxChecked = useRef(false)

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        isCheckboxChecked.current = e.target.checked
    }

    const handleButtonClick = () => {
        if (isCheckboxChecked.current || !price.value) {
            onSubmit(subscriptionId)
        } else {
            alert("Bitte akzeptiere die AGB's")
        }
    }

    const VATDisplay = useMemo(() => (inclVAT ? "inkl. MwSt." : "exkl. MwSt."), [inclVAT])
    const isDisabled = useMemo(() => typeof disabled === "string", [disabled])

    return (
        <div className="flex aspect-[7/10] flex-col justify-center rounded-3xl bg-[#6462B4]/40 px-6 py-5">
            <div data-card-header className="flex w-full flex-col justify-center font-label">
                <h3 className="text-center font-text text-body1">{type}</h3>
                <PricingCardPricing price={price} discountPrice={discountPrice} />
                <span className="text-center">/ Monat ({VATDisplay})</span>
            </div>
            <div data-card-body className="mt-6 flex flex-1 flex-col font-text text-small font-light">
                <Button
                    variant="primary"
                    type="button"
                    disabled={isDisabled}
                    onClick={handleButtonClick}
                    data-testid="buy-button"
                >
                    {isDisabled ? disabled : "Kaufen"}
                </Button>
                <span className="my-3">{description}</span>
                <SeparationLine />
                <div data-card-key-value-pairs className="my-4 h-full flex-grow">
                    {/* Will need to create a component for this */}
                    <div className="flex flex-col gap-2">
                        {attributes.map((attribute, index) => (
                            <PricingCardAttribute key={index} title={attribute.title} value={attribute.value} />
                        ))}
                    </div>
                </div>
            </div>
            {price.value > 0 && (!discountPrice || discountPrice.value > 0) && (
                <div data-card-footer className="font-text text-tiny font-light">
                    <SeparationLine />
                    <div className="my-3 flex justify-between">
                        <span className="w-full text-center">
                            Mit dem Kauf stimmst du unserer{" "}
                            <LinkButton
                                variant="freeform"
                                isExternal
                                href="https://enerithm.com/terms-and-conditions"
                                className="underline"
                            >
                                AGB
                            </LinkButton>{" "}
                            zu
                        </span>

                        <span>
                            <input
                                type="checkbox"
                                onChange={handleCheckboxChange}
                                className="accent-primary"
                                data-testid="terms-conditions-checkbox"
                            ></input>
                        </span>
                    </div>
                </div>
            )}
        </div>
    )
}

export { PricingCard }
export type { Props as PricingCardProps }
