import { useState } from "react"

type Option = "Jährlich" | "Monatlich"

interface Props {
    onToggle: (option: Option) => void
}

const ToggleSwitch = (props: Props) => {
    const { onToggle } = props
    const [selectedOption, setSelectedOption] = useState<Option>("Jährlich")

    const handleToggle = (option: Option) => {
        setSelectedOption(option)
        onToggle(option)
    }

    return (
        <div className="relative flex w-full select-none flex-col items-center">
            <div className="flex w-full justify-center font-text">
                <div
                    className={`text-2xl cursor-pointer px-4 font-bold transition-colors duration-300 ${
                        selectedOption === "Jährlich" ? "text-[#7aff2b]" : "text-white"
                    }`}
                    onClick={() => handleToggle("Jährlich")}
                    data-testid="yearly-toogle"
                >
                    Jährlich
                </div>
                <div
                    className={`text-2xl cursor-pointer px-4 font-bold transition-colors duration-300 ${
                        selectedOption === "Monatlich" ? "text-[#7aff2b]" : "text-white"
                    }`}
                    onClick={() => handleToggle("Monatlich")}
                    data-testid="monthly-toogle"
                >
                    Monatlich
                </div>
            </div>
            <div className="relative h-[3px] w-full bg-white">
                <div
                    className={`absolute h-[3px] bg-[#7aff2b] transition-transform duration-300 ${
                        selectedOption === "Jährlich" ? "left-0 w-1/2" : "right-0 w-1/2"
                    }`}
                ></div>
            </div>
        </div>
    )
}

export { ToggleSwitch }
export type { Option as ToggleSwitchInterval }
