import { useDrafts } from "@api/certificate/useDrafts"
import { GradientBorder } from "@components/atoms/gradient"
import { ContentOffset } from "@components/molecules/content-offset"
import { EnergyCertificatTypeMap } from "@energuide/shared"
import { useTitlebar } from "@hooks/useTitlebar"
import * as Form from "@radix-ui/react-form"
import { cls } from "@utils"
import { DateTime } from "luxon"
import React from "react"
import { ReviewCard } from "./review-card"

type ReviewSearchProps = {
    onInput: React.FormEventHandler<HTMLInputElement>
}

function ReviewSearch(props: ReviewSearchProps) {
    const { onInput } = props

    return (
        <Form.Root className={cls("grid justify-items-center *:w-full")} onSubmit={(event) => event.preventDefault()}>
            <GradientBorder variant="dark" radius="full">
                <Form.Field
                    name="search"
                    className="grid grid-cols-[auto,1fr,auto] gap-4 rounded-full bg-background py-2 pl-2 pr-4 text-primaryAccent2 shadow-xl outline
                        outline-1 outline-secondary/50"
                >
                    <div className="grid h-9 w-9 place-items-center rounded-full bg-secondary text-backgroundStrong">
                        <i className="ri-chat-search-line text-[1.5rem]"></i>
                    </div>
                    <Form.Control asChild className="min-w-0" autoFocus>
                        <input autoCapitalize="off" autoCorrect="off" onInput={onInput} className="bg-transparent" />
                    </Form.Control>
                </Form.Field>
            </GradientBorder>
        </Form.Root>
    )
}
export function ReviewList() {
    const { data: drafts } = useDrafts()
    const [search, setSearch] = React.useState("")

    useTitlebar({
        title: "Reviews",
        mode: "back",
        backTo: "/authenticated/chat",
        showContextMenu: false,
        showSearchIcon: false,
    })

    const filteredDrafts = React.useMemo(() => {
        if (!drafts) {
            return []
        }

        return drafts.filter((draft) => {
            const { taskId, project, type, createdAt } = draft
            const model = `
                ${taskId} 
                ${project?.id ?? ""} 
                ${project?.name ?? ""} 
                ${EnergyCertificatTypeMap[type]} 
                ${DateTime.fromISO(createdAt).toFormat("dd.MM.yyyy - kk:mm")}
            `

            return model.toLowerCase().includes(search.toLowerCase())
        })
    }, [drafts, search])

    const onInput: React.FormEventHandler<HTMLInputElement> = (event) => {
        const input = event.target as HTMLInputElement
        setSearch(input.value)
    }

    return (
        <ContentOffset safeAreas={false} offsetAppbar={true}>
            <section className="mx-auto grid w-full max-w-screen-md content-start gap-6 p-4">
                <ReviewSearch onInput={onInput} />
                {filteredDrafts?.map((draft) => <ReviewCard certificate={draft} key={draft.id} />) ?? []}
            </section>
        </ContentOffset>
    )
}
